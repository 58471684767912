import { AspectRatio, Image, StyleProps, Box, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "~/libs/i18n";
import CustomImage from "~/components/CustomImage";

const Thumbnail: FC<
  StyleProps & {
    src: string;
    url?: string;
    hightLight?: boolean;
  }
> = ({ src, url, hightLight, ...styleProps }) => {
  const t = useTranslation();
  const [imageSrc,setImageSrc] = useState<string>('/assets/images/website_placeholder.png')
  useEffect(()=>{
    if(src) setImageSrc(src)
  },[src])
  if (hightLight) {
    return (
      <AspectRatio w="full" ratio={1} {...styleProps}>
        <Box>
          <Box
            pos={"absolute"}
            bgColor={"brand.timable-yellow"}
            top={0}
            left={0}
            borderBottomRightRadius={6}
          >
            <Text
              px={{ base: 1, md: 2 }}
              py={1}
              fontSize={{ base: "xs", md: "sm" }}
              fontWeight={{ base: 500, md: 700 }}
              color={"#FFF"}
            >
              {t("event.hightLight")}
            </Text>
          </Box>
          <CustomImage
            objectFit={`cover`}
            src={imageSrc}
            fallbackSrc="/assets/images/website_placeholder.png"
          />
        </Box>
      </AspectRatio>
    );
  }
  return (
    <AspectRatio w="full" ratio={1} {...styleProps}>
      <CustomImage
        objectFit={`cover`}
        src={imageSrc}
        fallbackSrc="/assets/images/website_placeholder.png"
      />
    </AspectRatio>
  );
};

export default Thumbnail;
